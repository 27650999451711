@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
    font-family: "Inter", sans-serif;
}

.container {
    max-width: 1160px;
    margin: auto;
    padding: 0 20px;
}

input:hover, input:focus {
    border-color: #1E3D52 !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.ant-btn {
    box-shadow: none;
    font-size: 16px;
    height: 40px;
    word-break: break-all;
}

.ant-btn.ant-btn-primary:hover {
    background-color: #AE9F82 !important;
    color: #fff !important;
}

.ant-checkbox-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.ant-checkbox-group .ant-checkbox-wrapper {
    width: calc(50% - 5px);
}

.ant-form-item-label label {
    font-weight: 600;
}

.ant-form-item-label {
    padding-bottom: 5px !important;
}

.ant-form-item-label label span {
    margin-left: 10px;
}

.link_button {
    border-radius: 8px;
    background: #AE9F82;
    height: 40px;
    padding: 10px 12px;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.05px;
}

.link_button:hover {
    background-color: #1E3D52;
    color: #fff;
}


.ant-btn:focus-visible {
    outline: none !important;
}

.primary {
	background-color: #AE9F82;
	color: #fff;
}

.primary:hover {
	background-color: #AE9F82;
    color: #fff !important;
    border-color: unset !important;
}


@media (max-width: 767px) {
    .ant-btn {
        white-space: unset;
        height: auto;
        min-height: 40px;
        font-size: 14px;
    }    
}