.header {
    background-color: #FFFFFF;
    border-bottom: 1px solid #F7F5F3;
    padding: 0;
    height: auto;
}

.header_wrapper .logo img {
    width: 69.642px;
    height: 68.917px;
    display: block;
}

.header_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header .container {
    max-width: 100%;
    padding: 10px 32px;
}

.logout_modal .ant-modal-content {
    padding: 32px 24px 24px 24px;
    border-radius: 12px;
    box-shadow: -4px 0px 12px 0px rgba(0, 0, 0, 0.08);
}

.logout_modal .ant-modal-content img {
    display: block;
    margin: auto;
}

.logout_modal .ant-modal-content h2 {
    color: #121212;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 20px; /* 83.333% */
    letter-spacing: -0.02px;
    margin: 24px 0;
}

.logout_modal .ant-modal-content p {
    color: #121212;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.02px;
    margin: 0 0 24px;
}

.logout_modal .ant-modal-content .logout_buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.logout_modal .ant-modal-content .logout_buttons .ant-btn {
    height: 32px;
    width: calc(50% - 6px);
    padding: 0px 20px;
    border-radius: 8px;
    border: 1px solid #1E3D52;
    color: #1E3D52;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}

.logout_modal .ant-modal-content .logout_buttons .ant-btn:hover {
    background-color: #1E3D52;
    color: #FFFFFF;
}

.logout_modal .ant-modal-content .logout_buttons .ant-btn.logout_confirm {
    background: #1E3D52;
    color: #ffff;
}

.logout_modal .ant-modal-content .logout_buttons .ant-btn.logout_confirm:hover {
    background-color: #AE9F82;
    border-color: #AE9F82;
}

.header_menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.menu_item {
    display: flex;
    align-items: center;
    color: #AE9F82;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.02px;
}

.menu_item img {
    margin-right: 4px;
}

.separator_line {
    background: #F7F5F3;
    width: 1px;
    height: 21.542px;
    margin: 0 12px;
}

.header_menu .ant-dropdown-trigger {
    height: 20px;
    display: flex;
    align-items: center;
    color: #0A090B;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.02px;
}

.header_menu .ant-dropdown-trigger img {
    margin-left: 12px;
}

.header_menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.menu_item {
    display: flex;
    align-items: center;
    color: #AE9F82;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.02px;
}

.menu_item img {
    margin-right: 4px;
}

.separator_line {
    background: #F7F5F3;
    width: 1px;
    height: 21.542px;
    margin: 0 12px;
}

.header_menu .ant-dropdown-trigger {
    height: 20px;
    display: flex;
    align-items: center;
    color: #0A090B;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.02px;
}

.header_menu .ant-dropdown-trigger img {
    margin-left: 12px;
}

.ant-dropdown .ant-dropdown-menu {
    padding: 8px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
    min-width: 200px;
}

.user_details {
    color: #7F7D83;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    letter-spacing: -0.05px;
    border-bottom: 1px solid #F7F5F3;
    padding-bottom: 2px;
}

.user_details strong {
    display: block;
    color: #0A090B;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.02px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
    padding: 0;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .ant-btn {
    padding: 8px;
    height: 36px;
    border-radius: 4px;
    background: #F7F5F3;
    border-color: #F7F5F3;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.02px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 2px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .ant-btn img {
    margin-right: 20px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
    background-color: transparent;
}

@media (max-width: 767px) {
    .header .container {
        padding: 10px 12px;
    }
    
    .header_wrapper .logo img {
        width: 61.338px;
        height: 60.699px;
    }
    .logout_modal .ant-modal-content h2 {
        font-size: 22px;
    }
}