* {
    box-sizing: border-box;
}

.login_container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.login_image {
    width: 60%;
    height: 100vh;
}

.login_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.login_form_container {
    padding: 10px 20px;
    width: 40%;
    height: 100vh;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.login_form_container .login_form {
    width: 100%;
    max-width: 400px;
}

.login_form_container .login_form .logo {
    display: block;
    margin: auto;
    max-width: 166px;
}

.login_form_container .login_form p {
    margin: 0 0 27px;
    text-align: center;
    color: #4F4D55;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px; /* 125% */
}

.login-btn {
    height: 40px;
    width: 100%;
    background-color: #1E3D52;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}

.login-btn:hover {
    background-color: #AE9F82 !important;
}

@media (max-width: 1023px) {
    .login_image {
        width: 40%;
    }
    
    .login_form_container {
        width: 60%;
    }
}
@media (max-width: 767px) {
    .login_image {
        display: none;
    }
    
    .login_form_container {
        width: 100%;
    }
    .login_form {
        max-width: 100%;
    }
}