.clients_main {
    padding: 12px;
}

.clients_wrapper {
    padding: 12px;
    border-radius: 12px;
    background: #F7F5F3;
}

.clients_wrapper .container {
    max-width: 1680px;
    padding: 0;
    background-color: #fff;
    border-radius: 12px;
}

.clients_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #F7F5F3;
}

.clients_header h2 {
    color: #121212;
    font-size: 24px;
    font-weight: 600;
    line-height: 20px;
    /* 83.333% */
    letter-spacing: -0.02px;
    margin: 0;
}

.clients_header .add_button {
    border-radius: 8px;
    background: #AE9F82;
    display: block;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.05px;
    padding: 10px 12px;
}




.clients_main .clients_wrapper .clients_table_container .clients_table td,
.clients_main .clients_wrapper .clients_table_container .clients_table th {
    white-space: nowrap;
}

.clients_main .clients_wrapper .clients_table_container .clients_table th {
    color: #121212;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    /* 114.286% */
    letter-spacing: -0.02px;
    background-color: transparent;
    border: 0;
    border-top: 1px solid #F7F5F3;
    border-bottom: 1px solid #F7F5F3;
    padding: 15px 24px !important;
}

.clients_main .clients_wrapper .clients_table_container .clients_table th:before {
    display: none;
}

.clients_main .clients_wrapper .clients_table_container .clients_table td {
    color: #0A090B;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    border: 0;
    border-bottom: 1px solid #F7F5F3;
    padding: 15px 24px !important;
}

.clients_table {
    overflow: auto;
    position: relative;
}

.clients_table  .ant-btn {
    padding: 0;
    height: auto;
    border: none;
    background-color: transparent;
    margin: 0 8px;
}

.clients_main .clients_wrapper .clients_table_container .clients_table td:last-child, .clients_main .clients_wrapper .clients_table_container .clients_table th:last-child {
    width: 140px;
    text-align: center;
}

.clients_main .clients_wrapper .clients_table_container .clients_table td:first-child, .clients_main .clients_wrapper .clients_table_container .clients_table th:first-child {
    width: 70px;
}

.clients_form_main {
    padding: 12px;
}

.clients_form_wrapper {
    padding: 12px;
    border-radius: 12px;
    background: #F7F5F3;
}

.client_from_container {
    border-radius: 12px;
    background: #FFF;
    padding: 32px 24px;
    min-height: calc(100vh - 140px);
}

.client_from_header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 24px;
}

.client_from_header h2 {
    color: #0A090B;
    font-size: 24px;
    font-weight: 600;
    line-height: 20px; /* 83.333% */
    letter-spacing: -0.02px;
    margin: 0 0 0 12px;
}

.client_from_header .back_button {
    width: 36px;
    height: 36px;
    min-height: 36px;
    border-radius: 8px;
    border: 1px solid #CCC;
    background: #FFF;
    box-shadow: 0px 2px 4px -1px rgba(10, 9, 11, 0.02), 0px 5px 13px -5px rgba(10, 9, 11, 0.05);
    padding: 0;
}

.client_from_container .ant-form {
    max-width: 660px;
}


.client_from_container .ant-form .ant-form-item label {
    color: #7F7D83;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.05px;
}

.clients_form_buttons .ant-btn.ant-btn-default {
    border-radius: 8px;
    border: 1px solid #CCC;
    opacity: 0.8;
    background: #FFFFFF;
    color: #0A090B;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.02px;
}

.clients_form_buttons .ant-btn.ant-btn-primary {
    border-radius: 8px;
    background: #AE9F82;
    margin-left: 12px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.05px;
}

.client_from_container {
    padding: 12px;
}

.client_from_header h2 {
    font-size: 20px;
}

.clients_form_buttons .ant-btn.ant-btn-default {
    width: 100%;
    order: 2;
}

.clients_form_buttons .ant-btn.ant-btn-primary {
    width: 100%;
    margin-left: 0;
    margin-bottom: 12px;
    order: 1;
}

.clients_form_buttons .ant-form-item-control-input-content {
    display: flex;
    flex-wrap: wrap;
}

.client_item_action_dropdown.ant-dropdown .ant-dropdown-menu {
    border-radius: 12px;
    background: var(--fff, #FFF);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
    max-width: 117px;
    padding: 4px;
}

.client_item_action_dropdown.ant-dropdown .ant-dropdown-menu button {
    background-color: transparent !important;
    border: none !important;
}

.client_item_action_dropdown.ant-dropdown .ant-dropdown-menu button span {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.02px;
    display: block;
    margin-left: 8px;
}

.client_item_action_dropdown.ant-dropdown .ant-dropdown-menu button img {
    margin-right: 0 !important;
}

@media (max-width: 767px) {
    .clients_main .clients_wrapper .clients_table_container .clients_table td:last-child, .clients_main .clients_wrapper .clients_table_container .clients_table th:last-child {
        background-color: #fff;
    }
}