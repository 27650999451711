.products_form,
.products_main {
    padding: 12px;
}

.products_form .container,
.products_main .container {
    max-width: 1680px;
    background-color: #F7F7F3;
    border-radius: 12px;
    padding: 12px;
}

.products_form_wrapper .ant-steps,
.products_wrapper {
    border-radius: 12px;
    background: #FFF;
    padding: 12px;
}

.products_wrapper {
    padding: 0;
    min-height: calc(100vh - 138px);
}

.products_form_wrapper .ant-steps .ant-steps-item {
    flex: unset !important;
    padding: 0 24px 0 40px !important;
}

.products_form_wrapper .ant-steps .ant-steps-item:before {
    display: none !important;
}

.products_form_wrapper .ant-steps .ant-steps-item .ant-steps-item-icon {
    border-radius: 100px;
    border: 1px solid #CECECE;
    background: #FFF;
    padding: 10px;
    width: 40px;
    height: 40px;
    color: #4F4D55;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.18px;
}

.products_form_wrapper .ant-steps .ant-steps-item.ant-steps-item-process .ant-steps-item-icon {
    border: 1.75px solid #1E3D52;
    font-weight: 400;
}

.products_form_wrapper .ant-steps .ant-steps-item.ant-steps-item-process .ant-steps-item-icon .ant-steps-icon {
    color: #1E3D52;
}

.products_form_wrapper .ant-steps .ant-steps-item .ant-steps-item-container {
    display: flex;
    align-items: center;
    padding: 0;
    margin-inline-start: 0;
}

.products_form_wrapper .ant-steps .ant-steps-item .ant-steps-item-title {
    color: #AE9F82;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px !important;
    letter-spacing: -0.02px;
}

.products_form_wrapper .ant-steps .ant-steps-item.ant-steps-item-process .ant-steps-item-title {
    color: #1E3D52;
}

.products_form_wrapper .ant-steps .ant-steps-item:after {
    top: 20px;
}

.products_form_wrapper .ant-steps .ant-steps-item:first-child {
    padding-left: 0 !important;
}

.products_form_wrapper .ant-steps .ant-steps-item.ant-steps-item-finish .ant-steps-item-title {
    color: #1E3D52;
}

.products_form_wrapper .ant-steps .ant-steps-item.ant-steps-item-finish .ant-steps-item-icon {
    background-color: #1E3D52;
    padding: 5px;
}

.products_form_wrapper .ant-steps .ant-steps-item.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
    width: 28px;
    height: 28px;
    display: block;
    background-position: center;
    background-image: url('../../assets/images/double_check_icon.svg');
}

.products_form_content .ant-form-item {
    width: calc(50% - 12px);
}

.products_form_content .input_group {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.products_form_content .ant-form-item label {
    color: #7F7D83;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.05px;
}

.products_form_content .ant-form-item .ant-form-item-label {
    padding-bottom: 5px;
}


.products_form_content .ant-form-item .ant-form-item-control .ant-input {
    border-radius: 8px;
    border: 1px solid #CCC;
    background: #FFF;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    padding: 7px 11px;
}

.products_form_content .ant-upload-wrapper {
    margin-bottom: 24px;
    display: block;
}

.products_form_content .ant-form-item .ant-form-item-control .ant-checkbox-wrapper span {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.02px;
}

.products_form_content .ant-form-item .ant-form-item-control .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #AE9F82;
    border-color: #AE9F82;
}

.products_form_content .ant-form-item .ant-form-item-control .ant-switch.ant-switch-checked {
    background-color: #AE9F82;
}

.products_form_content h3 {
    font-weight: 500;
}

.products_form_content .ant-form-item .ant-form-item-control .ant-radio-wrapper span {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.02px;
}

.products_form_content .ant-form-item .ant-form-item-control .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background-color: #AE9F82;
    border-color: #AE9F82;
}

.products_form_content .ant-form-item.full_width {
    width: 100%;
}

.products_form_content {
    padding: 12px;
    border-radius: 12px;
    background-color: #fff;
    margin-top: 12px;
}

.products_form_content .ant-alert.ant-alert-info {
    margin-bottom: 24px;
}

.products_form_content .ant-alert {
    border-radius: 8px;
    border: 1px solid #29A1FF;
    background: #E6F4FF;
    padding: 24px;
}

.products_form_content .ant-alert .ant-alert-message {
    color: #121212;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.products_form_content .ant-alert .ant-alert-icon svg {
    width: 16px;
    height: 16px;
}

.products_form_content .ant-form-item .ant-form-item-control .list_item {
    border-radius: 100px !important;
    border: 1px solid #CCC;
    background: #FFF;
    color: #121212;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 9px 12px;
    margin-bottom: 12px;
}

.products_form_content .ant-form-item .ant-form-item-control .list_item img {
    display: block;
    margin-right: 4px;
}

.products_form_content .ant-form-item .ant-form-item-control ul .list_item {
    cursor: grab;
}

.products_form_content .ant-form-item .ant-form-item-control ul .list_item:focus {
    cursor: grabbing;
}

.products_form_content .ant-form-item .ant-form-item-control .list_item.disabled {
    background: #F2F2F2;
    color: #CCC;
    cursor: not-allowed;
}

.products_form_content .ant-form-item .ant-form-item-control .list_item.disabled img {
    opacity: 0.2;
}

.products_form_content .nutrition_form .input_group .ant-form-item .ant-form-item-control .ant-input {
    padding: 3px 0px;
    background-color: transparent;
    border: 0;
}

.category_from_section {
    border-radius: 12px;
    border: 1px solid #F7F5F3;
    padding: 12px 0px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.category_from_section .category_from_item {
    padding: 0 12px;
}

.category_from_section .category_from_item strong {
    color: #7F7D83;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.05px;
    display: block;
}

.category_from_section .category_from_item span {
    color: #121212;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    display: block;
    margin-top: 5px;
}

.category_from_inputs {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.category_from_inputs .category_input {
    display: flex;
    align-items: center;
}

.category_from_inputs .category_input .ant-form-item {
    width: 120px;
    margin: 0;
}

.category_from_inputs .category_input label {
    color: #121212;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    display: block;
    margin: 0 5px;
}

.nutrition_buttons {
    border-bottom: 1px solid #F7F5F3;
    padding: 18px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.nutrition_buttons .ant-btn {
    border-radius: 8px;
    border: 1px solid #1E3D52;
    background-color: transparent;
    color: #1E3D52;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.05px;
    padding: 0 12px;
    flex: 1;
    box-shadow: none;
    margin: 6px 0;
}

.nutrition_buttons .last_step_or {
    color: #CCC;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.05px;
    display: block;
    padding: 0 12px;
}

.nutrition_buttons .ant-btn:hover {
    border-color: #AE9F82;
}

.products_form_buttons {
    margin-top: 24px;
}

.products_form_buttons .ant-btn.ant-btn-default {
    border-color: #1E3D52;
    color: #1E3D52;
    margin-right: 24px;
}

.products_form_buttons .ant-btn.ant-btn-default:hover {
    background-color: #1E3D52;
    color: #fff;
}

.products_form_buttons .ant-btn.ant-btn-primary {
    background: #AE9F82;
    color: #fff;
}

.products_form_buttons .ant-btn {
    border-radius: 8px;
    width: 241px;
    height: 40px;
    padding: 0px 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.05px;
}

.products_form_buttons .ant-btn.ant-btn-primary:hover {
    background-color: #1E3D52 !important;
}

.products_form_content .ant-form-item .ant-form-item-control .ant-select {
    height: 40px;
}

.products_form_content .ant-form-item h4 {
    color: #7F7D83;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.05px;
}

.products_form_content .products_form_buttons .ant-form-item {
    width: 100%;
}


.products_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid #F7F5F3;
}

.products_heading h1 {
    margin: 0;
    color: #121212;
    font-size: 24px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.02px;
}

.product_sub_item,
.products_list_item_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #F7F5F3;
    padding: 4px 0;
}

.product_sub_item_content .product_sub_item_image {
    border-radius: 7.78px;
    border: 1px solid #F7F5F3;
    background: #FFF;
    width: 55.105px;
    height: 55.105px;
    margin: 0 24px;
    overflow: hidden;
}

.product_sub_item_content .product_sub_item_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.product_sub_item_content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% - 170px);
}

.product_sub_item_title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 104px);
}

.product_sub_item_action {
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product_sub_item_action a {
    display: block;
    margin: 0 6px;
}

.product_sub_item_action a span {
    display: none;
}

.products_list_item_heading h2 {
    color: #121212;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.02px;
    margin: 0;
}

.products_list_item_heading .product_sub_item_content .product_sub_item_image {
    opacity: 0;
}

.product_sub_item_title h3 {
    color: #121212;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0 4px 0 0;
}

.product_sub_item_title p {
    color: #CCC;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}






.qr-view {
    text-align: right;
}

.qr-view button {
    width: 100%;
}


.ant-checkbox {
    align-self: inherit;
}

.ant-checkbox .ant-checkbox-inner {
    top: 4px;
}

.category_modal .ant-modal-content {
    padding: 32px 24px 24px 24px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: -4px 0px 12px 0px rgba(0, 0, 0, 0.08);
}

.category_modal .ant-modal-content .ant-modal-header .ant-modal-title {
    color: #0A090B;
    font-size: 24px;
    font-weight: 600;
    line-height: 20px;
    /* 83.333% */
    letter-spacing: -0.02px;
    text-align: center;
}

.category_modal_wrapper {
    padding-top: 24px;
}

.category_modal .ant-modal-content .ant-modal-body .category_modal_wrapper .category_table td,
.category_modal .ant-modal-content .ant-modal-body .category_modal_wrapper .category_table th {
    white-space: nowrap;
}

.category_modal .ant-modal-content .ant-modal-body .category_modal_wrapper .category_table th {
    color: #121212;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    /* 114.286% */
    letter-spacing: -0.02px;
    background-color: transparent;
    border: 0;
    border-top: 1px solid #F7F5F3;
    border-bottom: 1px solid #F7F5F3;
    padding: 15px 24px;
}

.category_modal .ant-modal-content .ant-modal-body .category_modal_wrapper .category_table th:before {
    display: none;
}

.category_modal .ant-modal-content .ant-modal-body .category_modal_wrapper .category_table td {
    color: #0A090B;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    border: 0;
    border-bottom: 1px solid #F7F5F3;
    padding: 15px 24px;
}

.category_modal .ant-modal-content .ant-modal-body .category_modal_wrapper .category_table .ant-table-row-selected td {
    background-color: #1e3d52;
    color: #fff;
}

.category_modal .ant-modal-content .ant-modal-footer {
    margin-top: 24px;
}

.category_modal .ant-modal-content .ant-modal-footer .ant-btn {
    border-radius: 8px;
    border: 1px solid #1E3D52;
    padding: 7px 20px;
    height: 32px;
    color: #1E3D52;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    min-width: 120px;
}

.category_modal .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-primary {
    background-color: #AE9F82;
    border-color: #AE9F82;
    color: #fff;
}

.category_modal_wrapper .category_table .ant-table-content {
    overflow: auto;
}

td.ant-table-cell.ant-table-selection-column {
    display: none;
}

.product_view_main {
    padding: 12px;
}

.product_view_warapper {
    border-radius: 12px;
    background: #F7F5F3;
    padding: 12px;
}

.product_view_warapper .container {
    max-width: 1024px;
    padding: 0;
}

.product_view_header {
    border-radius: 12px;
    padding: 12px;
    background: #FFF;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.product_view_image {
    width: 352.654px;
    height: 352.654px;
    border-radius: 49.786px;
    border: 2px solid #F7F5F3;
    background: #FFF;
}

.product_view_image img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: 0;
}

.product_view_details {
    padding: 0 16px;
    width: calc(100% - 513px);
}

.product_view_details h3 {
    color: #121212;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    margin: 0 0 8px;
}

.product_view_buttons {
    width: 160px;
}
.product_view_buttons .ant-btn {
    width: 100%;
    margin-top: 4px;
    box-shadow: none;
    background-color: #fff;
    color: #AE9F82;
    border-color: #AE9F82;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}

.product_view_buttons .ant-btn:hover {
    background-color: #AE9F82;
    color: #fff !important;
    border-color: unset !important;
}

.product_view_details h4,
.product_view_details h5,
.product_view_details span {
    color: #CCC;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0 0 8px !important;
    display: block;
}


.product_view_content .ant-collapse {
    margin-top: 12px;
    margin-bottom: 0 !important;
}

.product_view_content .ant-collapse .ant-collapse-header {
    height: 56px;
    padding: 0px 8px 0px 12px;
    display: flex;
    align-items: center;
    border: 1px solid rgba(204, 204, 204, 0.00);
    background: #FFF;
    border-radius: 8px !important;
}

.product_view_content .ant-collapse .ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
    border-radius: 8px 8px 0px 0px !important;
}

.product_view_content .ant-collapse .ant-collapse-content {
    padding: 8px 8px 8px 12px;
    border: 1px solid rgba(204, 204, 204, 0.00);
    background: #FFF;
}

.product_view_content .ant-collapse .ant-collapse-content .ant-collapse-content-box {
    padding: 0;
}

.product_view_content .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-header-text {
    color: #121212;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
}

.info_wrapper .info_item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.info_wrapper .info_item span {
    color: #121212;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px; 
    display: block;
    width: 238.027px;
}

.product_view_content .ant-collapse .ant-collapse-content .text_only {
    color: #121212;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.product_view_buttons .language_dropdown {
    width: 100%;
    margin-bottom: 4px;
    height: 40px;
}

.product_view_buttons .language_dropdown .ant-select-selector {
    font-size: 16px;
    color: #121212;
    border: 1px solid #CCCCCC;
    background-image: url('../../assets/images/translate_icon.svg');
    background-repeat: no-repeat;
    background-position: 10px center;
    padding-left: 34px
}

.product_view_buttons .language_dropdown.ant-select-focused .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
@media (max-width: 1023px) {
    .products_form_content .ant-form-item {
        width: 100%;
    }

    .products_form_content .input_group .ant-form-item {
        width: calc(50% - 10px);
    }

    .category_from_section {
        flex-wrap: wrap;
    }

    .category_from_section .category_from_item.category_from_item_last {
        width: 100%;
        border-top: 1px solid #F7F5F3;
        margin-top: 12px;
        padding-top: 12px;
    }

    .nutrition_buttons .ant-btn {
        width: 100%;
        flex: unset;
    }
    .product_view_image {
        width: 190px;
        height: 190px;
        border-radius: 8px;
    }
    
    .product_view_details {
        width: calc(100% - 350px);
    }
}

@media (max-width: 767px) {
    .products_form_wrapper .ant-steps .ant-steps-item .ant-steps-item-title {
        font-size: 12px;
        font-weight: 500;
    }

    .products_form_wrapper .ant-steps {
        flex-direction: row;
    }

    .products_form_wrapper .ant-steps .ant-steps-item {
        flex: 1 !important;
        padding: 0px !important;
    }

    .products_form_wrapper .ant-steps .ant-steps-item:after {
        position: absolute !important;
        inset-inline-start: 100% !important;
        width: 8px !important;
        height: 8px !important;
        transform: translateY(-50%) translateX(-50%) rotate(45deg) !important;
        margin-bottom: 0 !important;
        top: 9px;
    }

    .products_form_wrapper .ant-steps .ant-steps-item .ant-steps-item-icon {
        opacity: 0;
    }

    .products_form_wrapper .ant-steps .ant-steps-item .ant-steps-item-content {
        min-height: auto;
    }

    .products_form_wrapper .ant-steps .ant-steps-item .ant-steps-item-icon {
        padding: 0;
        width: 14.25px;
        height: 13.5px;
        border: none;
        margin-right: 2px;
    }

    .products_form_wrapper .ant-steps .ant-steps-item.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
        width: 14.25px;
        height: 13.5px;
        top: 1px;
        background-image: url('../../assets/images/double_check_small_icon.svg');
    }

    .products_form_wrapper .ant-steps .ant-steps-item.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon .ant-steps-finish-icon {
        display: none;
    }

    .products_form_wrapper .ant-steps .ant-steps-item.ant-steps-item-finish .ant-steps-item-icon {
        background-color: transparent;
        padding: 0;
        opacity: 1;
    }

    .products_form_wrapper .ant-steps {
        padding: 8px;
    }

    .products_form .container {
        padding: 8px;
    }

    .products_form {
        padding: 8px;
    }

    .products_form_wrapper .ant-steps .ant-steps-item.ant-steps-item-finish:nth-child(2) {
        padding-left: 12px !important;
    }

    .products_form_content .input_group .ant-form-item {
        width: 100%;
    }

    .products_form_buttons .ant-btn {
        width: 100%;
    }

    .products_form_content .ant-form-item .ant-form-item-control .list_item {
        font-size: 14px;
    }

    .products_form_buttons .ant-btn.ant-btn-default {
        margin-right: 0;
        order: 2;
        margin-top: 12px;
    }

    .products_form_buttons .ant-form-item-control-input-content {
        display: flex;
        flex-wrap: wrap;
    }

    .products_form_buttons .ant-btn.ant-btn-primary:hover {
        background-color: #AE9F82 !important;
    }

    .products_form_content .ant-alert .ant-alert-message {
        font-size: 14px;
    }

    .products_form_content .ant-alert {
        padding: 12px;
        align-items: flex-start;
    }

    .products_form_content .ant-alert .anticon {
        margin-top: 6px;
    }

    .category_from_section .category_from_item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
    }

    .category_from_section .category_from_item.category_from_item_last {
        display: block;
        border: none;
        margin-top: 0;
    }

    .category_from_inputs {
        flex-wrap: wrap;
    }

    .category_from_inputs .category_input {
        width: 100%;
        margin: 2.5px 0;
    }

    .category_from_inputs .category_input .ant-form-item {
        width: calc(100% - 70px);
    }

    .category_from_inputs .category_input label {
        width: 70px;
        text-align: right;
    }

    .nutrition_buttons .ant-btn {
        padding: 4px 12px;
    }

    .product_sub_item_action {
        background: #FFF;
        box-shadow: -8px 0px 12px 0px rgba(0, 0, 0, 0.08);
        width: 48px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
    }

    .product_sub_item,
    .products_list_item_heading {
        position: relative;
    }

    .products_list_item_heading .product_sub_item_action h2 {
        display: none;
    }

    .product_sub_item_action a {
        width: 48px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
    }

    .product_item_action_dropdown.ant-dropdown .ant-dropdown-menu {
        border-radius: 12px;
        background: var(--fff, #FFF);
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
        max-width: 117px;
        padding: 4px;
    }

    .product_item_action_dropdown.ant-dropdown .ant-dropdown-menu a {
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .product_item_action_dropdown.ant-dropdown .ant-dropdown-menu a span {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: -0.02px;
        display: block;
        margin-left: 8px;
    }

    .products_heading h1 {
        font-size: 20px;
    }

    .product_sub_item_content {
        width: calc(100% - 50px);
    }

    .product_sub_item_content .product_sub_item_image {
        width: 46.078px;
        height: 46.078px;
        margin: 0 12px;
    }

    .product_sub_item_title {
        width: calc(100% - 80px);
    }

    .product_sub_item_title h3 {
        font-size: 14px;
    }

    .product_sub_item_title p {
        font-size: 14px;
    }

    .category_modal .ant-modal-content {
        padding: 32px 12px 24px 12px;
    }

    .category_modal .ant-modal-content .ant-modal-header .ant-modal-title {
        font-size: 18px;
    }

    .category_modal .ant-modal-content .ant-modal-body .category_modal_wrapper .category_table th {
        padding: 10px 12px;
    }

    .category_modal .ant-modal-content .ant-modal-body .category_modal_wrapper .category_table td {
        padding: 10px 12px;
    }

    .category_modal_wrapper .category_table .ant-table-content {
        position: relative;
    }

    .category_modal_wrapper .category_table .ant-table-content:before {
        content: "";
        position: absolute;
        left: 1px;
        top: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset -200px 0px 110px -62px rgba(255, 255, 255, 1);
        z-index: 1;
        pointer-events: none;
    }

    .category_modal_wrapper .category_table .ant-table-ping-left .ant-table-content:before {
        display: none;
    }

    .category_modal .ant-modal-content .ant-modal-footer {
        display: flex;
        flex-wrap: wrap;
    }

    .category_modal .ant-modal-content .ant-modal-footer .ant-btn {
        width: 100%;
        order: 2;
    }

    .category_modal .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-primary {
        margin: 0 0 12px;
        order: 1;
    }
    .product_view_image {
        width: 100%;
        height: 318px;
    }
    
    .product_view_details {
        width: 100%;
        padding: 24px 0px 12px;
        text-align: center;
    }

    .product_view_header {
        margin-top: 10px;
    }
    
    .product_view_buttons {
        width: 100%;
	}
    
    .product_view_buttons .product-qr {
        margin: auto;
    }
    
    .info_wrapper .info_item {
        justify-content: space-between;
    }
    
    .info_wrapper .info_item span {
        width: calc(50% - 20px);
    }
    
    .info_wrapper .info_item span:last-child {
        text-align: right;
    }
}

@media (max-width: 600px) {
    .ant-checkbox-group .ant-checkbox-wrapper {
        width: 100%;
    }

    .step2 .ant-checkbox-group .ant-checkbox-wrapper {
        width: 100% !important;
    }
}
